import moment from 'moment';
import $ from 'jquery';
import headerTitle from '../utils/headerTitle';

export default class MonthSelector {
  element = document.querySelector('.month-selector');
  monthSelectEl = this.element.querySelector('select.month');
  prevBtnEl = this.element.querySelector('button.monthprev');
  nextBtnEl = this.element.querySelector('button.monthnext');

  init(){
    this._fillSelect();
    this._setEvents();
    this._resetSelected();

    // Set prev/next buttons on init
    $(this.monthSelectEl).change();
  }

  _fillSelect() {
    for (let i = 0; i <= 13; i++){
      const currentMonth = moment().subtract(i, 'months')
      const option = document.createElement("option");

      option.value = currentMonth.format('YYYYMM')
      option.innerText = moment().isSame(currentMonth, 'year') ? currentMonth.format('MMMM') : currentMonth.format('MMMM YYYY')

      this.monthSelectEl.appendChild(option);
    }
  }

  _setEvents() {
    $(this.prevBtnEl).on('click', (el) => {
      this.setPeriod(el.currentTarget.dataset.value)
    })

    $(this.nextBtnEl).on('click', (el) => {
      this.setPeriod(el.currentTarget.dataset.value)
    })

    $(this.monthSelectEl).on('change',  (el) => {
      const period = el.currentTarget.value
      this.setPrevNextMonthBtn(period)
      headerTitle(this.getDateString());

      if (this.onMonthChanged) {
        this.onMonthChanged(period)
      }
    });
  }

  // Reset because some browsers keep the selection
  _resetSelected() {
    this.monthSelectEl.selectedIndex = 0;
  }

  setPeriod(yearMonth) {
    $(this.monthSelectEl).val(yearMonth).change();
  }

  setPrevNextMonthBtn(yearMonth) {
    const prevMonth = this._calculateMonth(yearMonth, -1)
    const nextMonth = this._calculateMonth(yearMonth, 1);

    if(moment().diff(prevMonth, 'months') <= 13){
      this._setButtonAttr(this.prevBtnEl, prevMonth);
    } else {
      this.prevBtnEl.setAttribute('disabled', 'true');
    }

    if(!this._calculateMonth(moment(), 1).isSame(nextMonth, 'month')){
      this._setButtonAttr(this.nextBtnEl, nextMonth);
    } else {
      this.nextBtnEl.setAttribute('disabled', '');
    }
  }

  _setButtonAttr(btnEl, momentDate) {
    btnEl.title = momentDate.format('MMMM YYYY');
    btnEl.setAttribute('data-value', momentDate.format('YYYYMM'));
    btnEl.classList.remove('disabled');
    btnEl.removeAttribute('disabled');
  }

  _calculateMonth(yearMonth, diff) {
    if(yearMonth === "recent" || yearMonth === "year"){
      return moment();
    } else {
      return moment(yearMonth, 'YYYYMM').add(diff, 'months')
    }
  }

  getDateString() {
    return this.monthSelectEl.options[this.monthSelectEl.selectedIndex].text;
  }

  getSelectedOption() {
    return this.monthSelectEl.options[this.monthSelectEl.selectedIndex].value;
  }
}
