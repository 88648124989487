import Chart from 'chart.js/auto';
import chartConfig, { sessionChartColours } from '../config/chart-config';

const chartEl = document.querySelector('.chart-canvas.-overview')

export default function overviewShowInit() {
  if (chartEl) {
    chartConfig();
    chart();
  }
}

function chart() {
  const videoData = JSON.parse(chartEl.dataset.videoData);
  const audioData = JSON.parse(chartEl.dataset.audioData);
  const chartLabels = JSON.parse(chartEl.dataset.chartLabels);

  if (chartLabels.length && (videoData.length || audioData.length)) {
    new Chart(chartEl, {
      type: 'line',
      data: {
        labels: chartLabels,
        datasets: [{
          label: 'video',
          data: videoData,
          ...
          sessionChartColours.video
        },
        {
          label: 'audio',
          data: audioData,
          ...
          sessionChartColours.audio
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          mode: 'index', // Show data of all lines at that point
          position: 'nearest' // Show tooltip at nearest point
        },
        elements: {
          point: {pointBorderWidth: 1},
          line: {tension: 0.2} // straightness of line
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'end',
            padding: 15,
            labels: {
              boxHeight: 3,
              boxWidth: 3,
              usePointStyle: true
            }
          },
        },
        scales: {
          x: {display: false},
          y: {display: false}
        }
      }
    })
  }
}
