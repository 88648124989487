import $ from 'jquery';

let timeout;
let warned;
const keywords = i18n.events.privacy_keywords;
const backoffTime = 500;
const animationDuration = 200;
const $warning = $('.privacy-warning');

export function setTimeOut(event) {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => analyze(event.target.value.toLowerCase(), backoffTime));
}

export function closeWarning(e) {
  e.preventDefault();
  $warning.slideUp(animationDuration);
}

function analyze(value) {
  if (!warned && isPublic() && value.match(keywords)) {
    warned = true;
    showWarning();
  }
}

function isPublic() {
  return $('#event_privacy_archive_video_public')[0].checked || $('#event_privacy_archive_audio_public')[0].checked;
}

function showWarning() {
  $warning.slideDown(animationDuration);
  $warning.removeClass('_hidden');
}
