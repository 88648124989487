import StatsRow from './statsRow';
import moment from 'moment';

export default class StatsRowMonth extends StatsRow {
  dateFormat = 'MMMM';
  apiRowData = 'getMonth';

  _getDate() {
    return moment(this.data.period, 'YYYYMM');
  }
}
