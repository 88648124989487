import Clipboard from 'clipboard'

const btns = document.querySelectorAll('[data-copy-self-btn]')

export default function markupInfo() {

  if (btns) {
    new Clipboard(btns, {
      target: function (trigger) {
        return trigger;
      }
    });
  }
}
