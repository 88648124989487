import StatsTableEvent from './stats/components/statsTableEvent';
import StatsTableEventSessions from './stats/components/statsTableEventSessions';
import StatsTableMonth from './stats/components/statsTableMonth';
import moment from 'moment';

const tableEl = document.querySelector('.stats-table');

export default function statisticsInit(){
  moment.locale(i18n?.locale || 'en');

  if (tableEl) {
    setupTable(tableEl);
  }
}

function setupTable(tableEl) {
  let statsTable;

  switch (tableEl.dataset.tableType) {
    case 'event':
      statsTable = new StatsTableEvent();
      break;
    case 'event-session':
      statsTable = new StatsTableEventSessions();
      break;
    default:
      statsTable = new StatsTableMonth();
  }

  statsTable.init(tableEl);
}
