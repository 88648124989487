import StatsTable from './statsTable';
import StatsRowMonth from './statsRowMonth';
import moment from 'moment';

export default class StatsTableMonth extends StatsTable {
  statsRow = StatsRowMonth;
  apiIndex = 'getMonths';
  pageLimit = 13;

  _exportTitle() {
    const title = super._exportTitle();
    const lastMonth = moment().subtract(this.pageLimit, 'months');
    const format = 'MMMM YYYY';
    return `${title} ${lastMonth.format(format)}-${moment().format(format)}`;
  }
}
