import $ from 'jquery';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

export default function presetList() {
  const presetListEl = document.querySelector('.preset-list');

  if (presetListEl) {
    new PresetList(presetListEl);
  }
}

class PresetList {
  length;
  dialogEl = $('.dialog-confirm');
  spinnerEl = document.querySelector('.loading-spinner');

  constructor(el) {
    this.element = el;
    this.addChurchUrl = el.dataset.addChurchUrl;
    this.addPresetUrl = el.dataset.addPresetUrl;

    this.makeSortable();
    this.addEventListeners();
    this.updateSortableList();
  }

  makeSortable() {
    Sortable.create(this.element, {
      delay: 10,
      animation: 150,
      ghostClass: '-placeholder',
      dragClass: '-dragitem',
      onUpdate: () => this.updateSortableList()
    });
  }

  addEventListeners() {
    $(document).on('click', '.btn.-add-recording', () => this.addRecording());
    $(document).on('click', '.btn.-add-church', () => this.openDialog());
    $(document).on('click', '.btn.-delete', (e) => this.deleteItem(e.target));
    $(document).on('click', '.church-list .btn', (e) => this.addChurch(e.target));
    $(document).on('change', '.hasarchive', () => this.updateSortableList());
  }

  spin(spin = true) {
    this.spinnerEl.classList.toggle('-spinning', spin);
  }

  addRecording() {
    const url = this.addPresetUrl + `?presettable_type=Archive&index=${this.getListLength()}`;
    this.fetchNewPreset(url);
  }

  openDialog() {
    this.dialogEl.dialog({ modal: true, height: 500, appendTo: '.page-content' });

    $.get(this.addChurchUrl, (data => this.dialogEl.html(data)));
  }

  addChurch(el) {
    const {id, type} = el.dataset;
    const url = this.addPresetUrl + `?presettable_type=${type}&index=${this.getListLength()}&presettable_id=${id}`;

    this.fetchNewPreset(url)
        .done(this.dialogEl.dialog('close'));
  }

  deleteItem(el) {
    const liEL = $(el).parents('li');
    this.spin();

    liEL.slideUp('slow', () => {
      liEL.addClass('destroyed');
      liEL.find('.destroy').attr('value', '1');
      this.updateSortableList();
    });
  }

  updateSortableList() {
    this.setSortItems();
    this.setRecordingTitles();
    this.spin(false);
  }

  setSortItems() {
    $(this.element).find('li:not(.destroyed)').each((i, el) => {
      const hasArchive = $(el).find('.hasarchive').prop('checked');
      const replace = hasArchive ? ["s", "S"] :["S", "s"];

      $(el).attr('signature', $(el).attr('signature').replace(replace));
      $(el).find('.position').attr('value', i + 1);
    });
  }

  setRecordingTitles() {
    const stationsString = this.getStationsString();

    $(this.element).find('.listitem.-archive:not(.destroyed) .title').each((i, el) => {
      const shortTitle = $(el).parents('li').attr('short_title');

      $(el).html(`${shortTitle} ${i + 1} <small>- ${stationsString || i18n.preset_list.no_church }</small>`);
    });
  }

  getStationsString() {
    const stations = this.element.querySelectorAll('.listitem.-station');
    const archiveStations = Array.from(stations)
        .filter(el => el.querySelector('.hasarchive:checked'))
        .map(el => el.getAttribute('short_title'));

    return archiveStations.join(', ')
  }

  fetchNewPreset(url) {
    this.spin();

    return $.get(url, (htmlString) => {
      const parser = new DOMParser();
      const html = parser.parseFromString(htmlString, 'text/html');

      $(html.querySelector('li')).appendTo(this.element)
    })
      .fail( error =>  {
        console.error(error);
        alert(i18n.defaults.error_occurred);
      })
      .done(() => this.updateSortableList());
  }

  getListLength() {
    return this.length = this.length === undefined ? $(this.element).find('li').length : this.length + 1;
  }
}
