const spraypaint = require('spraypaint/dist/spraypaint')

export default class StatsApi {
  spinnerEl = document.querySelector('.loading-spinner');

  // Spraypaint settings
  apiNamespace;
  baseUrl;
  token;
  accountId;
  attr;
  applicationRecord;

  // Models
  Event;
  Month;
  Session;

  constructor(dataset){
    this._initStatsApi(dataset);
    this._authHeaders();
    this._defineModels();
  }
  
  getEvents(pageLimit, period) {
    return this.Event
      .where({ period: period })
      .per(pageLimit)
      .selectExtra(['detailedSessionsCount'])
      .all()
      .catch((error) => this._showError(error))
  }

  getMonths(pageLimit) {
    return this.Month
      .per(pageLimit)
      .selectExtra(['detailedSessionsCount'])
      .all()
      .catch((error) => this._showError(error))
  }

  getEvent(id) {
    return this.Event
      .selectExtra(['detailedSessionsCount', 'liveSessionsChart'])
      .find(id)
      .catch((error)=> this._showError(error))
  }

  getSessions(pageLimit, eventId) {
    return this.Session
      .where({ event_id: eventId })
      .per(pageLimit)
      .all()
      .catch((error)=> this._showError(error))
  }

  getMonth(id) {
    return this.Month
      .selectExtra(['detailedSessionsCount'])
      .find(id)
      .catch((error)=> this._showError(error))
  }

  _showError(error) {
    console.error(error);
    this._hideSpinner();
    document.querySelector('.alert-box.-xhrerror').classList.remove('_hidden');
  }

  _initStatsApi(dataset) {
    this.apiNamespace = dataset.namespace;
    this.baseUrl = dataset.baseUrl;
    this.token = dataset.token;
    this.accountId = dataset.accountId;

    this.attr = spraypaint.attr;
    this.applicationRecord = spraypaint.SpraypaintBase.extend({
      static: {
        baseUrl: this.baseUrl,
        apiNamespace: this.apiNamespace,
      },
    });
  }

  _authHeaders() {
    if (!this.token || !this.accountId) {
      this._showError('Token or accountId not found')
    }

    const middleware = new spraypaint.MiddlewareStack();

    middleware.beforeFilters.push((url, options) => {
      options.headers["Authorization"] = "Token " + this.token;
      options.headers["Stats-Account-Id"] = this.accountId;

      this._showSpinner();
    });

    middleware.afterFilters.push(() => this._hideSpinner());

    this.applicationRecord.middlewareStack = middleware;
  }

  _showSpinner() {
    this.spinnerEl.classList.add('-spinning');
  }

  _hideSpinner() {
    this.spinnerEl.classList.remove('-spinning');
  }

  _defineModels(){
    const baseModel = this.applicationRecord.extend({
      attrs: {
        sessionsCount: this.attr(),
        detailedSessionsCount: this.attr(),
      },
      methods: {
        viewsTotals: function () {
          const {live, archive} = this.detailedSessionsCount;

          return {
            sessions: this.sessionsCount.total,
            audio: live.audio.total + archive.audio.total,
            video: live.video.total + archive.video.total,
          };
        }
      }
    });

    this.Month = baseModel.extend({
      static: {
        jsonapiType: "months",
      },
      attrs: {
        period: this.attr(),
      }
    });

    this.Event = baseModel.extend({
      static: {
        jsonapiType: "events",
      },
      attrs: {
        startsAt: this.attr(),
        endsAt: this.attr(),
        duration: this.attr(),
        liveSessionsChart: this.attr(),
      },
      methods:{
        mediaPeak: function () {
          function getPeak(data, key) {
            return data[key] ? Math.max(...data[key]) : 0;
          }

          return {
            peakAudio: getPeak(this.liveSessionsChart, 'audio'),
            peakVideo: getPeak(this.liveSessionsChart, 'video'),
          };
        }
      }
    });

    this.Session = this.applicationRecord.extend({
       static: {
         jsonapiType: "sessions",
       },
       attrs: {
         duration: this.attr(),
         startsAt: this.attr(),
         endsAt: this.attr(),
         bytes: this.attr(),
         serial: this.attr(),
         agent: this.attr(),
         additionalInfo: this.attr(),
         sessionType: this.attr(),
         mediaType: this.attr()
       }
     });
  }
}
