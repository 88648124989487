import TimeFormatter from './timeFormatter';
import moment from 'moment';

export default class Exporter {
  dateFormat = 'MMMM YYYY';
  formatTime =  new TimeFormatter();
  station;

  columnPath = new Map([
    ['date', 'startsAt'],
    ['period', 'period'],
    ['startsAt', 'startsAt'],
    ['endsAt', 'endsAt'],
    ['length', 'duration'],
    ['live_audio', 'detailedSessionsCount.live.audio.total'],
    ['live_video_not_billable','detailedSessionsCount.live.video.not_billable'],
    ['live_video_billable','detailedSessionsCount.live.video.billable'],
    ['live_total','detailedSessionsCount.live.total'],
    ['archive_audio','detailedSessionsCount.archive.audio.total'],
    ['archive_video_not_billable','detailedSessionsCount.archive.video.not_billable'],
    ['archive_video_billable','detailedSessionsCount.archive.video.billable'],
    ['archive_totals','detailedSessionsCount.archive.total'],
    ['totals', 'detailedSessionsCount.total']
  ])

  columnOrder = [
    'period',
    'live_total',
    'archive_totals',
    'totals'
  ]

  extendedColumnOrder = [
    'period',
    'live_audio',
    'live_video_not_billable',
    'live_video_billable',
    'live_total',
    'archive_audio',
    'archive_video_not_billable',
    'archive_video_billable',
    'archive_totals',
    'totals'
  ]

  constructor(dataset) {
    this.station = dataset.station;
  }

  getCsvContent(data, extended){
    const columnOrder = extended ? this.extendedColumnOrder : this.columnOrder;
    return this._getCsvHeader(columnOrder) + this._getCsvBody(data, columnOrder);
  }

  _getCsvHeader(columnOrder){
    return columnOrder.map(column => i18n.stats.legend[column]) + '\n'
  }

  _getCsvBody(data, columnOrder) {
    return data.reduceRight((accumulator, row) => {
      const columns = columnOrder.map(column => {
        const value = this.getFromObject(row, this.columnPath.get(column));
        return this._formatValue(column, row, value);
      })

      return accumulator + columns.join(',') + "\n";
    }, '')
  }

  _formatValue(column, row, value) {
    switch(column){
      case 'date':
      case 'period':
        return this._formatDate(value)
      case 'startsAt':
        return this.formatTime.format('startsAt', row)
      case 'endsAt':
        return this.formatTime.format('endsAt', row)
      case 'length':
        return this.formatTime.format('length', row) || i18n.stats.separate_recording
      default :
        return value
    }
  }

  getFromObject(object, path){
    return path.split('.').reduce((currentObj, childObj) => currentObj?.[childObj], object)
  }

  _formatDate(date){
    return moment(date).format(this.dateFormat)
  }

  downloadFile(data, title, extended){
    const blob = new Blob([this.getCsvContent(data, extended)], {type: "text/csv"});
    const href = URL.createObjectURL(blob);

    const fileLink = this._createFileLink(href, this._getFileName(title, extended));
    fileLink.click();

    URL.revokeObjectURL(href);
  }

  _createFileLink(href, fileName){
    const fileLink = document.createElement('a');
    fileLink.href = href;
    fileLink.download = fileName;
    return fileLink;
  }

  _getFileName(title, extended){
    const timeStamp = moment().format('YYMMDDHHmm');
    const extendedI18n = extended ?  '(' + i18n.stats.export.extended + ')' : '';
    const fileName =  [title, extendedI18n, this.station, timeStamp].filter(Boolean).join(' ');

    return fileName + '.csv';
  }
}
