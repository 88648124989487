import $ from "jquery";

export default function datepicker() {
  setDefaults();
  init();
}

function init() {
  $('.datepicker').datepicker({firstDay: 7, dateFormat: 'dd-mm-yy'});

  // Selected date might be in the past, therefore set minDate on show
  $('.datepicker.-future').datepicker('option', 'beforeShow', function () {
    $(this).datepicker('option', 'minDate', new Date());
  });

  $('.datepicker:not(.-future)').datepicker('option', 'minDate', '-365d');
}

function setDefaults() {
  $.datepicker.setDefaults({
    dayNamesMin: emphasizeFirstWeekDay(),
    showOtherMonths: true,
    selectOtherMonths: true,
    maxDate: '+18m',
    onClose: function (dateText, inst) {
      updateDateFields(this, dateText, inst.id);
    },
    beforeShowDay: function (date) {
      return beforeShowDay(date)
    }
  });

  // Needs to be set separately
  $.datepicker.setDefaults($.datepicker.regional[i18n.locale]);
}

function emphasizeFirstWeekDay() {
  const dayNames = $.datepicker.regional[i18n.locale].dayNames;
  let dayNamesShort = $.datepicker.regional[i18n.locale].dayNamesMin;

  dayNamesShort.splice(0, 1, dayNames[0]);

  return dayNamesShort;
}

export function updateDateFields(self, incoming_datestring, src_id) {
  const target = $(self).attr('target');

  if (incoming_datestring) {
    const datestring = incoming_datestring.split('-');
    const date = new Date(datestring[2], parseInt(datestring[1], 10) - 1, parseInt(datestring[0], 10), 23, 59, 59);

    if ($(self).hasClass('date-selector-plain')) {
      $('#' + target).val(formatDate(date));
    } else {
      $('#' + target + '_1i').val(datestring[2]);
      $('#' + target + '_2i').val(parseInt(datestring[1], 10));
      $('#' + target + '_3i').val(parseInt(datestring[0], 10));
      $('#' + src_id).prop('selectedIndex', date.getDay());
    }

    $('#' + self.id).datepicker('setDate', date);
  }
}

function beforeShowDay(date) {
  const selectedRecurrence = $('#event_recurrence_options_FREQ option:selected');

  if (selectedRecurrence.length > 0 && selectedRecurrence.attr('value').search(/WEEKLY/) >= 0) {
    if (date.getDay() === parseInt($('#event_recurrence_options_BYDAY').prop("selectedIndex"))) {
      if ($(this).data('starting')) {
        return [date >= new Date($(this).data('starting')), ''];
      }

      return [true, ''];
    }

    return [false, '', i18n.defaults.invalid_choice];
  }

  return [true, ''];
}

function pad(i) {
  return (i < 10 ? '0' : '') + i;
}

function formatDate(d) {
  let s = d.getUTCFullYear();
  s += pad(d.getUTCMonth() + 1);
  s += pad(d.getUTCDate());
  return s;
}
