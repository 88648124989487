let scrollPosition;
const collapsibleMenuItems = document.querySelectorAll('[data-menu-collapse]');

export default function initiateMenu() {
  const toggleMainMenuBtns = document.querySelectorAll('[data-toggle-menu]');
  let touchMoved;

  collapsibleMenuItems.forEach( el => {
    el.addEventListener('touchstart', () => touchMoved = false)
    el.addEventListener('touchmove', () => touchMoved = true)
    el.addEventListener('touchend', (e) => { if (!touchMoved) _expandSubMenu(e, el)})
    el.addEventListener('keydown', (e) => _collapseOnKey(e, el))
  })

  toggleMainMenuBtns.forEach( el => el.addEventListener('touchstart', () => _toggleMainMenu(el)))
}

function _collapseOnKey(e, el) {
  if(['Enter','Space'].includes(e.code)) {
    _expandSubMenu(e, el)
  }
}

function _expandSubMenu(e, el) {
  const subMenuEl = el.nextElementSibling;
  const hideSubMenu = subMenuEl.classList.contains('-hidden');

  e.preventDefault();
  _closeSubMenus();

  if(subMenuEl.classList.contains('menu')) {
    _toggleSubMenu(subMenuEl, !hideSubMenu);
    _setLinkState(el, hideSubMenu)
  }
}

function _closeSubMenus() {
  const subMenus = document.querySelectorAll('.main-menu .menu .menu')

  subMenus.forEach(subMenu => _toggleSubMenu(subMenu, true));
  collapsibleMenuItems.forEach(menuItem => _setLinkState(menuItem, false))
}

function _toggleSubMenu(el, toggle) {
  el.classList.toggle('-hidden', toggle);
}

function _setLinkState(el, toggle) {
  el.classList.toggle('-collapsed', toggle);
  el.parentElement.setAttribute('aria-expanded', toggle);
}

function _toggleMainMenu() {
  _setScrollPosition();
  
  const menuOpen = document.body.classList.toggle('-mobilemenuopen');
  const scrollTo = menuOpen ? 0 : scrollPosition;

  window.scrollTo(0, scrollTo);
}

function _setScrollPosition(){
  if(!document.body.classList.contains('-mobilemenuopen')){
    scrollPosition = window.scrollY;
  }
}
