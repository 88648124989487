import Clipboard from 'clipboard';
import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/arduino-light.css';

export default function codeBlock() {
  highlightCodeBlocks();
  addCopyCodeEvents();
};

function highlightCodeBlocks() {
  hljs.registerLanguage('xml', xml);

  document.querySelectorAll('pre code')
    .forEach(block => hljs.highlightElement(block));
}

function addCopyCodeEvents() {
  const btn = document.querySelectorAll('[data-copy-btn]')

  if (btn) {
    const clipboard = new Clipboard(btn);

    clipboard.on('success', function (e) {
      const icon = e.trigger.querySelector('.icon')
      icon.className = 'icon check'
    });

    clipboard.on('error', function (e) {
      const icon = e.trigger.querySelector('.icon')
      icon.className = 'icon highlight-remove'
    });
  }
}


