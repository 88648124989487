import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default function dropdowns() {
  const dropdowns = document.querySelectorAll(`[data-dropdown]`);

  tippy(dropdowns, {
    content(el) {
      return el.parentElement.querySelector('.content');
    },
    onShow(dropdown) {
      this.content.onclick = () => dropdown.hide()
    },
    allowHTML: true,
    interactive: true,
    placement: 'bottom-start',
    maxWidth: 'none',
    arrow: false,
    trigger: 'click',
    offset: [0, 5],
    delay: [0, 300] // For slower mouse users
  });
}