import ProgressBar from "./progress-bar";

export default function directUpload() {
  const processUploadEl = document.querySelector('.progress-bar.-processing');

  if (processUploadEl) {
    new ProcessProgressBar(processUploadEl);
  }
}

class ProcessProgressBar extends ProgressBar {
  url;
  interval;

  constructor(props) {
    super(props);

    this.url = this.element.dataset.url;
    if (this.url) {
      this.element.classList.add('-pending');
      this.getProgress();
    }
  }

  async getProgress() {
    const response = await fetch(this.url);
    const { transcode_progress, status } = await response.json();

    if (status === "failed") {
      this.error();
      this.setProgress(100);
    } else if (transcode_progress === 100) {
      this.finished();
    } else {
      this.setProgress(transcode_progress);
      setTimeout(() => {this.getProgress()}, 1000);
    }
  }

  setProgress(progress) {
    super.setProgress(progress);

    this.element.classList.remove('-pending');
  }

  error () {
    super.error();
    clearInterval(this.interval);
  }

   finished() {
    this.element.classList.add('-completed');
    clearInterval(this.interval);
    window.location.reload();
  }
}
