import $ from 'jquery';
import updateDateFields from '../components/datepicker';
import { setTimeOut, closeWarning} from '../components/privacy-warning';

export default function formEventInit() {
  setEventListeners();
}

function setEventListeners() {
  $(document).on('change', '#event_recurrence_options_FREQ', () => showRecurrenceFields());
  $(document).on('change', '#event_enable_until', (e) => toggleEndDate(e.target));
  $(document).on('change', '#event_recurrence_options_BYDAY', (e) => setDayOfWeek(e.target));
  $(document).on('input paste','#event_title', (e) => setTimeOut(e));
  $(document).on('click', '.privacy-warning .btn-close', (e) => closeWarning(e));
}

function showRecurrenceFields() {
  const recurrence = $(`#event_recurrence_options_FREQ option:selected`).attr('value');

  $('.recurrence-field').addClass("_hidden");

  if (!recurrence) {
    clearEndDate()
  } else if (recurrence.includes('DAILY')) {
    showEndDateSelection();
  } else if (recurrence.includes('WEEKLY')) {
    showEndDateAndDayOfWeek();
  }
}

function clearEndDate() {
  $("#event_recurrence_options_UNTIL").val('');
}

function showEndDateSelection(){
  const hasEndDate = document.querySelector('#recurrence-has-end input').checked;

  $('#recurrence-has-end').removeClass("_hidden");

  if (hasEndDate) {
    $('#recurrence-end-date').removeClass('_hidden');
  }
}

function showEndDateAndDayOfWeek() {
  $('#recurrence_weekly').removeClass("_hidden");

  showEndDateSelection();
  setDayOfWeek($('#event_recurrence_options_BYDAY'));
}

function toggleEndDate(el) {
  const $eventUntil = $('#event_until');

  $('#recurrence-end-date').toggleClass('_hidden');

  if (el.checked && $eventUntil.val()) {
    updateDateFields($eventUntil, $eventUntil.val(), 'event_recurrence_options_UNTIL');
  } else {
    $('#event_recurrence_options_UNTIL').val('');
  }
}

function setDayOfWeek(el) {
  const $year = $('#event_start_at_1i');
  const $month = $('#event_start_at_2i');
  const $day = $('#event_start_at_3i');

  const selected_date = new Date($year.val(), parseInt($month.val() - 1), $day.val());
  const this_wday = selected_date.getDay();
  const target_wday = $(el).prop("selectedIndex");

  selected_date.setDate(selected_date.getDate() - this_wday + target_wday);

  $('#event_start_at_text').datepicker('setDate', selected_date);

  $year.val(selected_date.getFullYear());
  $month.val(selected_date.getMonth() + 1);
  $day.val(selected_date.getDate());
}
