import StatsRow from './statsRow';
import StatsRowDetailEvent from './statsRowDetailEvent';
import TimeFormatter from '../utils/timeFormatter';

export default class StatsRowEvent extends StatsRow {
  dateFormat = 'ddd DD MMMM';
  apiRowData = 'getEvent';
  statsRowDetail = StatsRowDetailEvent;
  formatTime =  new TimeFormatter();

  _getHeaderContent(data) {
    super._getHeaderContent(data);

    this.headerContent.splice(1, 0,
      { key: "startsAt", value: this.formatTime.format('startsAt', data) },
      { key: "endsAt", value: this.formatTime.format('endsAt', data) },
      { key: "length", value: this.formatTime.format('length', data)  || i18n.stats.separate_recording}
    );
  }
}
