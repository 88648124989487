export default class FillElWithText{
  array(parentEl, textArray, data) {
    textArray.map(key => this.single(parentEl, key, data[key]))
  }

  objectArray(parentEl, objectArray) {
    objectArray.map(object => this.single(parentEl, object.key, object.value))
  }

  single(parentEl, key, value) {
    const el = parentEl.querySelector('.' + this._dasherize(key)) || parentEl.querySelector('.-' + this._dasherize(key));

    if(el && ![undefined, ''].includes(value)) {
      el.classList.remove('_hidden');
      const textEl = el.querySelector('.content') || el;
      textEl.innerText = value;
    }
  }

  _dasherize(camelCase){
    return camelCase.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
  }
}
