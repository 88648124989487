import Exporter from './exporter';

export default class ExporterEvents extends Exporter {
  dateFormat = 'DD-MM-YYYY';

  columnOrder = [
    'date',
    'startsAt',
    'endsAt',
    'length',
    'live_total',
    'archive_totals',
    'totals'
  ]

  extendedColumnOrder = [
    'date',
    'startsAt',
    'endsAt',
    'length',
    'live_audio',
    'live_video_not_billable',
    'live_video_billable',
    'live_total',
    'archive_audio',
    'archive_video_not_billable',
    'archive_video_billable',
    'archive_totals',
    'totals'
  ]
}
