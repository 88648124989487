// Based on: https://edgeguides.rubyonrails.org/active_storage_overview.html#example

export default class ProgressBar {
  element;

  constructor(element) {
    this.element = element;
  }

  setProgress(progress) {
    const progressElement = this.element.querySelector('.progress');
    const iconElement = this.element.querySelector('.icon.-loading');

    iconElement.classList.add('_hidden');

    progressElement.style.width = `${progress}%`;
  }

  error() {
    this.element.classList.add('-failed');
  }

}
