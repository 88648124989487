import $ from 'jquery';
import DoughnutChart from '../charts/doughnutChart';
import FillElWithText from '../utils/fillElWithText';

export default class StatsRowDetail {
  charts = [];
  element;

  constructor(element, data) {
    this.element = element;
    this._fillContent(data);
  }

  destroy() {
    this.charts.forEach(chart => chart.destroy())
  }

  _fillContent(data) {
    this._fillTexts(data);
    this._createCharts(data);

    this.element.classList.add('has-data');
    $('.stats-alert').addClass('_hidden')
  }

  _fillTexts(data) {
    const contentEl = this.element.querySelector('.row-content .section.-views');
    let elements = ['sessions', 'audio','video']

    new FillElWithText().array(contentEl, elements, data.viewsTotals());
  }

  _createCharts(data) {
    this._createDonutChart(data, 'live');
    this._createDonutChart(data, 'archive');
  }

  _createDonutChart(data, type){
    const chartData = data.detailedSessionsCount[type];

    if(chartData.total) {
      this.charts.push(new DoughnutChart(this._getChartCtx(type), chartData))
    } else {
      this.element.querySelector(`.section.-${type}`).classList.add('invisible');
    }
  }

  _getChartCtx(chartName) {
    return this.element.querySelector(`.${chartName}-chart canvas`).getContext('2d');
  }
}
