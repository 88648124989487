export default function fileSizeCheck() {
  const directUploadFileInput = document.getElementById('direct_upload_mezzanine');

  if (directUploadFileInput) {
    new FileSizeChecker(directUploadFileInput);
  }
}

class FileSizeChecker {
  maxSize = 1024 * 1024 * 1024 * 5; // 5GB

  constructor(element) {
    this.element = element;
    this.addEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener('change', (e) => this.inputChanged(e));
  }

  inputChanged(event) {
    Array.from(event.target.files).forEach((file) => {
      const fileSize = file.size;
      const errorEl = this.element.parentElement.querySelector('.alert-box');
      const submitButton = document.querySelector("input[type=submit]")
      const classList = errorEl.classList;

      if (fileSize > this.maxSize) {
        classList.remove('_hidden');
        submitButton.disabled = true;
      } else {
        classList.add('_hidden');
        submitButton.disabled = false;
      }
    });
  }
}
