import tippy from 'tippy.js';

export default function tooltips() {
  const tooltips = document.querySelectorAll(`[data-tippy-content]`);

  tippy(tooltips, {
    allowHTML: true,
    interactive: true,
    delay: [20, 100] // For slower mouse users
  });
}
