import StatsRowDetail from './statsRowDetail';
import FillElWithText from '../utils/fillElWithText';
import moment from 'moment';

export default class StatsRow {
  element;
  data;
  id;
  dateFormat;
  apiRowData;
  charts = [];
  headerContent = [];
  statsApi;
  statsRowDetail = StatsRowDetail;

  constructor(data, statsApi) {
    this.data = data;
    this.id = data.id;
    this.statsApi = statsApi;
  }

  init() {
    this.element = this._createElement();
    this._getHeaderContent(this.data);
    this._fillHeader();
    this._setClickEvent();
  }

  open() {
    if (!this.element.classList.contains('has-data')){
      this.statsApi[this.apiRowData](this.id)
        .then( record => this.statsRowContent = new this.statsRowDetail(this.element, record.data) )
    }

    this.element.classList.toggle('opened');
  }

  destroy() {
    if( this.statsRowContent ){
      this.statsRowContent.destroy();
    }
  }

  _createElement() {
    const template = document.querySelector('.stats-row-empty');
    const clone = template.content.cloneNode(true).firstChild;

    clone.setAttribute( 'id', this.id );
    clone.classList.remove('_hidden')
    return clone;
  }

  _fillHeader() {
    const headerEl = this.element.querySelector('.statsrow-header');

    this.headerContent.forEach( row => {
      new FillElWithText().single(headerEl, row.key, row.value);
    })
  }

  _getHeaderContent(data) {
    this.headerContent = [
      { key: "date", value: this._formatDate()},
      { key: "live", value: data.sessionsCount.live},
      { key: "archive", value: data.sessionsCount.archive},
      { key: "totals", value: data.sessionsCount.total}
    ]
  }

  _getDate() {
    return this.data.startsAt;
  }

  _formatDate() {
    const date = this._getDate();
    const format = moment().isSame(moment(date), 'year') ? this.dateFormat : this.dateFormat + ' YYYY';

    return date ? moment(date).format(format) : date;
  }

  _setClickEvent() {
    this.element.querySelector('.statsrow-header').onclick = () => this.open();
  }
}
