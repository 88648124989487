/**
 * Admin app.js
 */

import Rails from '@rails/ujs';
import base from './config/base'
import codeBlock from './components/code-block'
import directUpload from './components/direct-upload'
import fileSizeCheck from './components/file-size-check.js'
import dropdowns from './components/drop-downs.js'
import envInfo from './components/env-info'
import imageCropper from './components/image-cropper'
import tooltips from './components/tooltips'
import markupInfo from './components/markup-info'
import monthSwitcher from "./components/month-switcher";
import presetList from "./components/preset-list";
import priceTable from "./components/price-table";
import processUpload from './components/process-upload'
import radioSwitch from './components/radio-switch'
import datePicker from './components/datepicker'
import initiateMenu from './components/menu.js'
import thumbnailSelect from './components/thumbnail-selector'
import eventIndexInit from './pages/event-index'
import overviewShowInit from './pages/overview-show'
import formEventInit from './pages/form-event'
import profileEditInit from './pages/profile-edit'
import statisticsInit from './pages/statistics'
import * as ActiveStorage from '@rails/activestorage'

require('./vendor/jquery-ui.min');
require('./vendor/jquery-ui-i18n');

Rails.start();
ActiveStorage.start();
base();

// Components
initiateMenu();
codeBlock();
datePicker();
directUpload();
fileSizeCheck();
dropdowns();
envInfo();
imageCropper();
processUpload();
presetList();
radioSwitch();
tooltips();
markupInfo();
thumbnailSelect();
monthSwitcher();
priceTable();

// Pages
eventIndexInit();
overviewShowInit();
formEventInit();
profileEditInit();
statisticsInit();
