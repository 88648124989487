import moment from 'moment';

export default class TimeFormatter {
  timeFormat = 'HH:mm';

  format(type, event) {
    switch (type) {
      case 'startsAt':
        return this._formatStartsAt(event.startsAt)
      case 'endsAt':
        return this._formatEndsAt(event.duration, event.startsAt, event.endsAt)
      case 'length':
        return this.formatDuration(event.duration);
      default :
        return event
    }
  }

  _formatStartsAt(start){
    return start ? moment(start).format(this.timeFormat) : start
  }

  _formatEndsAt(duration, start, end){
    const format = moment(start).isSame(moment(end), 'day') ? this.timeFormat : 'DD MMM ' + this.timeFormat;
    return duration ? moment(end).format(format) : undefined;
  }

  formatDuration(duration) {
    if (duration) {
      const momentDuration = moment.duration(duration, 'seconds');
      const hours = momentDuration.asHours().toString().split('.')[0];
      const minutes = momentDuration.get('minutes');
      const seconds = momentDuration.get('seconds');

      return `${this._padWithZero(hours)}:${this._padWithZero(minutes)}:${this._padWithZero(seconds)}`;
    }
  }

  _padWithZero(number){
    return number.toString().padStart(2, '0');
  }
}

