const inputSelector = '.price-table input'
const activeClass = '-active'
const columnQuery = '.column'
const inputEls = document.querySelectorAll(inputSelector);

export default function priceTable() {
  if (inputEls) {
    addEventListeners();
  }
}

function addEventListeners() {
  [...inputEls].map(input => input.onchange = e => toggleRadioParent(e.target))

  // Chrome sets input after loading of page when navigating back
  document.addEventListener("readystatechange", () => setActiveRadio());
}

function setActiveRadio() {
  const checkedInputs = document.querySelectorAll(inputSelector + ':checked');

  [...checkedInputs].map(input => input.parentElement.classList.add(activeClass));
}

function toggleRadioParent(el) {
  const inputs = document.querySelectorAll(inputSelector + `[name="${el.name}"]`);

  [...inputs].map(input => input.closest(columnQuery).classList.remove(activeClass))
  el.closest(columnQuery).classList.add(activeClass);
}
