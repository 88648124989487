import $ from "jquery";

const denominationSelectEl = document.querySelector('#station_denomination_ids');
const countryEl = document.querySelector('#station_address_attributes_country');

export default function profileEditInit() {
  if(countryEl && denominationSelectEl) {
    setEventListeners();
  }
}

function setEventListeners() {
  countryEl.addEventListener('change', (e) => setDenominations(e.target))
}

function setDenominations(el) {
  const url = `${el.dataset.url}?country=${el.value}`

  $.get(url, '_method=get', null, 'script');
}
