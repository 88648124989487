import moment from 'moment';
import TimeFormatter from '../utils/timeFormatter';
import FillElWithText from '../utils/fillElWithText';
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js';

export default class DrillDownModal  {
  contentEl = document.querySelector('.drilldown-modal');
  formatTime =  new TimeFormatter();
  timeFormat = 'HH:mm:ss';
  pageLimit = 1000;
  modal;
  eventId;

  constructor() {
    this.modal = new Modal(this.contentEl)
  }

  createModal(id, summaryData, statsApi) {
    this.eventId = id;
    this.statsApi = statsApi;

    this._fillSummary(summaryData);
    this._getSessionsAndShowModal(id);
  }

  _fillSummary(summaryData){
    new FillElWithText().single(this.contentEl, 'eventId', this.eventId);
    new FillElWithText().objectArray(this.contentEl, summaryData);
  }

  _getSessionsAndShowModal() {
    this._clearSessionElements();

    this.statsApi.getSessions(this.pageLimit, this.eventId)
      .then( response => {
        if(response.data.length) {
          this._groupAndFillSessions(response.data);
        }

        this._setSessionCount(response.data);
        this._togglePageLimitWarning(response.data)
        this._showModal();
      })
  }

  _setSessionCount(sessions) {
    new FillElWithText().single(this.contentEl, 'session-count', sessions.length);
  }

  _togglePageLimitWarning(sessions) {
    const warningElClass = this.contentEl.querySelector('.page-limit-warning').classList;

    if(sessions.length >= this.pageLimit) {
      warningElClass.remove('hidden')
    } else {
      warningElClass.add('hidden')
    }
  }

  _groupAndFillSessions(sessions){
    const sessionGroup = this._groupSessions(sessions);

     for(const group in sessionGroup){
       this._fillSessionByGroup(group, sessionGroup[group]);
     }
  }

  _groupSessions(sessions) {
    this._sortSessions(sessions);
    return this._groupBy(sessions,  ['mediaType', 'sessionType']);
  }

  _sortSessions(sessions) {
    sessions.sort((a,b) => {
      a = a.sessionType.toLowerCase().includes('broadcast');
      b = b.sessionType.toLowerCase().includes('broadcast');

      return b - a;
    })
  }

  _groupBy(array, groupBy) {
    return array.reduce((storage, session) => {
      const category = this._getCategory(session, groupBy);
      storage[category] = storage[category] || [];
      storage[category].push(session);
      return storage;
    }, {});
  }

  _getCategory(session, groupBy) {
    if(Array.isArray(groupBy)){
      return groupBy.map((el) => session[el]).join(' ')
    } return session[groupBy];
  }

  _fillSessionByGroup(groupType, sessions) {
    const sessionGroupEl = this._createSessionGroupElement();

    new FillElWithText().single(sessionGroupEl, 'groupType', groupType);
    new FillElWithText().single(sessionGroupEl, 'count', sessions.length);

    sessions.forEach(session => {
      this._fillSessionRow(sessionGroupEl, session)
    })

    this.contentEl.querySelector('.modal-body').appendChild(sessionGroupEl);
  }

  _fillSessionRow(sessionGroupEl, session) {
    const sessionEl = this._createSessionRow(sessionGroupEl);
    const elements = ['duration', 'startsAt', 'endsAt', 'bytes', 'serial','agent', 'additionalInfo','sessionType', 'mediaType'];

    new FillElWithText().array(sessionEl, elements, this._formatSession(session));

    sessionGroupEl.querySelector('tbody').appendChild(sessionEl);

    // Wait until added to DOM
    setTimeout(() => this._addExpandEvents(sessionEl), 0)
  }

  _addExpandEvents(sessionEl) {
    const expandEls = sessionEl.querySelectorAll('.expand-overflow');

    expandEls.forEach((expandEl) => {
      const hasOverflow = expandEl.scrollWidth > expandEl.offsetWidth;

      if(hasOverflow) {
        expandEl.onclick = () => expandEl.classList.toggle('expanded')
      } else {
        expandEl.classList.remove('expand-overflow')
      }
    })
  }

  _formatSession(session){
    session.duration = this._formatDuration(session.startsAt, session.endsAt)
    session.startsAt = this._formatTime(session.startsAt)
    session.endsAt = this._formatTime(session.endsAt)
    return session;
  }

  _formatTime(date) {
    return moment(date).format(this.timeFormat)
  }

  _formatDuration(start, end){
    start = moment(start);
    end = moment(end);
    const diff = end.diff(start, 'seconds');

    return this.formatTime.formatDuration(diff);
  }

  _clearSessionElements() {
    const elements = this.contentEl.getElementsByClassName('session-group');

    while (elements.length > 0) elements[0].remove();
  }

  _createSessionGroupElement() {
    const clone = document.querySelector('.session-empty').cloneNode( true );
    clone.classList.remove('hidden', 'session-empty');

    return clone;
  }

  _createSessionRow(parentEl) {
    const clone = parentEl.querySelector('.session').cloneNode( true );
    clone.classList.remove('hidden');

    return clone;
  }

  _showModal() {
    this.modal.show();
    const bodyEl = this.contentEl.querySelector('.modal-body');
    bodyEl.scrollTop = 0;
  }
}
