import SessionsChart from '../charts/sessionsChart';
import StatsRowDetail from './statsRowDetail';
import FillElWithText from '../utils/fillElWithText';

export default class StatsRowDetailEvent extends StatsRowDetail {
  _fillTexts(data) {
    super._fillTexts(data);

    const contentEl = this.element.querySelector('.row-content .section.-views');
    const elements = ['peakAudio', 'peakVideo'];
    new FillElWithText().array(contentEl, elements, data.mediaPeak());
  }

  _createCharts(data) {
    super._createCharts(data);

    if(data.sessionsCount.live){
      this.charts.push(new SessionsChart(this._getChartCtx('sessions'), data.liveSessionsChart))
    }
  }
}
