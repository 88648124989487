import StatsTable from './statsTable';
import StatsRowEvent from './statsRowEvent';
import ExporterEvents from '../utils/exporterEvents';
import MonthSelector from './monthSelector';

export default class StatsTableEvent extends StatsTable {
  statsRow = StatsRowEvent;
  apiIndex = 'getEvents';
  recentPageLimit = 10;
  exporter = ExporterEvents;

  init(el) {
    this._initMonthSelector();
    super.init(el);
  }

  _initMonthSelector(){
    this.monthSelector = new MonthSelector();
    this.monthSelector.init();
    this.monthSelector.onMonthChanged = (period) => this.getDataAndFillRows(period)
  }

  _getPageLimit() {
    const period = this.monthSelector.getSelectedOption();
    return period === 'recent' ? this.recentPageLimit : this.pageLimit
  }

  _exportTitle() {
    const title = super._exportTitle();
    return `${title} ${this.monthSelector.getDateString()}`;
  }
}
