import Chart from 'chart.js/auto';

// Pastel map colours
export const colour = {
  yellow: 'rgb(242,193,78)',
  purple: 'rgb(158,120,143)',
  green: 'rgb(124,181,132)',
  blue: 'rgb(106,144,165)',
  kdgmDarkBlue: '#294057'
}

export const sessionChartColours = {
  audio: {
    backgroundColor: 'rgba(106,144,165, 0.3)',
    pointBackgroundColor: colour.blue,
    borderColor: colour.blue,
  },
  video: {
    backgroundColor: 'rgba(158,120,143, 0.3)',
    pointBackgroundColor: colour.purple,
    borderColor: colour.purple,
  }
}

export default function chartSettings() {
  Chart.defaults.interaction.intersect = false; // Show tooltip even when not exactly on point

  Chart.defaults.plugins.tooltip.backgroundColor = colour.kdgmDarkBlue;
  Chart.defaults.plugins.tooltip.caretPadding = 5;
  Chart.defaults.plugins.tooltip.titleFontSize = 13;
  Chart.defaults.plugins.tooltip.bodyFontSize = 12;
  Chart.defaults.plugins.tooltip.xPadding = 10;
  Chart.defaults.plugins.tooltip.yPadding = 10;
  Chart.defaults.plugins.tooltip.displayColors = false; // Show color box

  Chart.defaults.elements.line.fill = true; // Make chart have background-color
  Chart.defaults.elements.line.borderWidth = 2;
  Chart.defaults.elements.point.hoverRadius = 5;

  Chart.defaults.hoverBackgroundColor = '#333'; // Might need to be set separately
  Chart.defaults.hoverBorderColor = '#333';
}
