import $ from "jquery";

export default function eventIndexInit(){
  highlightRecurringEvents()
}

function highlightRecurringEvents(){
  $(document).on({
    mouseenter: function() {
      $(`[data-event-id="${ $(this).attr('data-event-id') }"]`).addClass('hover');
    },
    mouseleave: function() {
      $(`[data-event-id="${ $(this).attr('data-event-id') }"]`).removeClass('hover');
    }
  }, '.-recurring-btn');
}
