export default function thumbnailSelect() {
  const thumbnailBtn = document.querySelector('button.-thumbnail');

  if (thumbnailBtn) {
    new ThumbnailSelector(thumbnailBtn).init();
  }
}

class ThumbnailSelector {
  player;
  currentTime;
  thumbnailBtn;
  videoPlayed = false;
  iframe = document.querySelector('.kdgm-responsive-player iframe');
  rangeInput = document.querySelector('.slider input');
  posterInputEl = document.getElementById('video_file_poster');
  seekBarSecondEl = document.querySelector('.seekbar-fine');
  thumbnailInfoEl = document.querySelector('.-info.-thumbnail');

  constructor(thumbnailBtn) {
    this.thumbnailBtn = thumbnailBtn;

    this.thumbnailBtn.onclick = () => this.setThumbnail();
  }

  init() {
    const videojs = this.iframe.contentWindow.videojs;

    videojs ? this.initPlayer(videojs) : this.awaitVideoJs();
  }

  awaitVideoJs() {
    setTimeout(() => this.init(), 500);
  }

  initPlayer(videojs) {
    const playerEl = this.iframe.contentDocument.querySelector('.video-js');
    this.player = videojs(playerEl);

    this.player.on('pause', () => this.pauseHandler());
    this.player.on('playing', () => this.playingHandler());
  }

  pauseHandler() {
    if (!this.player.seeking()) {
      this.currentTime = this.player.currentTime();
      this.showSeekbar();
      this.player.on('seeking', () => this.setCurrentTime());
    }
  }

  playingHandler() {
    this.videoPlayed = true;
    this.hideSeekbar();
    this.player.off('seeking', () => this.setCurrentTime());
  }

  showSeekbar() {
    this.rangeInput.oninput = (e) => this.setPlayerTime(e);
    this.seekBarSecondEl.classList.add('-opened');
  }

  hideSeekbar() {
    this.seekBarSecondEl.classList.remove('-opened');
  }

  setPlayerTime(event) {
    this.player.currentTime(this.currentTime + parseFloat(event.target.value));
  }

  setCurrentTime() {
    if (this.player.scrubbing()) {
      this.currentTime = this.player.currentTime();
      this.rangeInput.value = 0;
    }
  }

  setThumbnail() {
    this.thumbnailInfoEl.classList.remove('_hidden');

    if(this.videoPlayed) {
      const image = this.getImageFromVideo();
      this.thumbnailBtn.querySelector('img').src = image;
      this.posterInputEl.value = image;
    }
  }

  getImageFromVideo() {
    const canvas = document.createElement('canvas');
    canvas.width = this.player.videoWidth();
    canvas.height = this.player.videoHeight();
    canvas.getContext('2d').drawImage(this.player.children_[0], 0, 0);
    return canvas.toDataURL('image/jpeg');
  }
}
