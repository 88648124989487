import StatsRowEvent from './statsRowEvent';
import DrillDownModal from "./drillDownModal";

export default class StatsRowEventSessions extends StatsRowEvent {
  _drillDown(e) {
    e.stopImmediatePropagation();
    new DrillDownModal().createModal(this.id, this.headerContent, this.statsApi);
  }

  _setClickEvent() {
    super._setClickEvent();
    this.element.querySelector('.btn-drilldown').onclick = (e) => this._drillDown(e);
  }
}
