const envInfoEl = document.querySelector(`.env-info`);
const swapBtnEl = document.querySelector(`.btn.-swap`);

export default function envInfo() {
  if(envInfoEl) closeOnClick()
}

function closeOnClick() {
  envInfoEl.onclick = (e) => {
    if (e.target.classList.contains("-swap")) return;

    e.target.style.display = "none"
    swapBtnEl.style.display = "none"
  };
}
