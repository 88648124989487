const switchInputs = '.radio-switch input'

export default function radioSwitch() {
  setActiveRadio();
  setRadioParentOnChange()
}

function setActiveRadio() {
  const inputs = document.querySelectorAll(switchInputs + ':checked');

  [...inputs].map(input => input.parentElement.classList.add('-active'));
}

function setRadioParentOnChange() {
  const inputs = document.querySelectorAll(switchInputs);

  [...inputs].map(input => input.onchange = e => toggleRadioParent(e.target))
}

function toggleRadioParent(el) {
  const inputs = document.querySelectorAll(switchInputs + `[name="${el.name}"]`);

  [...inputs].map(input => input.parentElement.classList.toggle('-active', input.checked))
}
