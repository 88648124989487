import Chart from 'chart.js/auto';
import chartSettings, { colour } from '../../../config/chart-config'

export default class DoughnutChart {
  ctx;
  data;

  dataElements = [
    {
      key: "radio",
      label: i18n.stats.labels.lukas,
      colour: colour.yellow,
      mediaType: "audio"
    },
    {
      key: "other",
      label: i18n.stats.labels.internet,
      colour: colour.blue,
      mediaType: "audio"
    },
    {
      key: "not_billable",
      label: i18n.stats.labels.not_billable,
      colour: colour.purple,
      mediaType: "video"
    },
    {
      key: "billable",
      label: i18n.stats.labels.billable,
      colour: colour.green,
      mediaType: "video"
    },
  ];

  dataSets = [];
  backgroundColors = [];
  labels = [];

  constructor(ctx, data) {
    this.ctx = ctx;
    this.data = data;
    chartSettings();
    this._setDataSets();
    this._createChart();

    return this.chart;
  }

  _setDataSets() {
    this.dataElements.forEach((elem) => {
      this.dataSets.push(this.data[elem.mediaType][elem.key]);
      this.backgroundColors.push(elem.colour);
      this.labels.push(elem.label);
    });
  }

  _createChart() {
    this.chart = new Chart(this.ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: this.dataSets,
          backgroundColor: this.backgroundColors
        }],
        labels: this.labels
      },
      plugins: [htmlLegendPlugin],
      options: {
        animations: false,
        // Create half doughnut appearance
        rotation: -90,
        circumference: 180,
        aspectRatio: 2,
        plugins: {
          legend: {
            display: false,
          }
        },
      }
    });

  };
}

const htmlLegendPlugin = {
  afterUpdate(chart, args, options) {
    const sum = chart.getDatasetMeta(0).total;
    const legendEl = chart.canvas.parentElement.parentElement.querySelector('.legend')

    for (let i = 0; i <= 3; i++) {
      const el = legendEl.querySelector('[data-legend-item="' + i + '"]');
      const colorBoxEl = el.querySelector('.colorbox');
      const labelEl = el.querySelector('[data-content="label"]');
      const valueEl = el.querySelector('[data-content="value"]');
      const percentageEl = el.querySelector('[data-content="percentage"]');
      const value = chart.data.datasets[0].data[i];

      colorBoxEl.setAttribute('style', 'background-color:' + chart.data.datasets[0].backgroundColor[i])
      labelEl.innerText = chart.data.labels[i];
      valueEl.innerText = value
      percentageEl.innerText = (value * 100 / sum).toFixed(1) + '%';
    }
  }
}
