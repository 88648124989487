// Based on: https://edgeguides.rubyonrails.org/active_storage_overview.html#example
import ProgressBar from "./progress-bar";

export default function directUpload() {
  const directUploadEl = document.querySelector('.progress-bar.-direct-upload');

  if (directUploadEl) {
    new UploadProgressBar(directUploadEl);
  }
}

class UploadProgressBar extends ProgressBar {

  constructor(props) {
    super(props);
    this.addEventListeners();
  }

  addEventListeners() {
    addEventListener('direct-upload:initialize', e => this.init(e))
    addEventListener('direct-upload:start', () => this.started())
    addEventListener('direct-upload:progress', (e) => this.progress(e))
    addEventListener('direct-upload:error', (e) => this.error(e))
    addEventListener('direct-upload:end', () => this.end())
  }

  init(e) {
    const { detail, target } = e;
    this.input = target;

    this.element.querySelector('.filename').textContent = detail.file.name;
    this.element.classList.add('-visible');
    this.input.classList.add('_hidden');
    this.disableForm(true);
  }

  disableForm(disable ) {
    document.querySelectorAll('fieldset').forEach(fieldset => {
        if (disable) { fieldset.setAttribute('disabled', 'true')}
        else { fieldset.removeAttribute('disabled') }
      }
    )
  }

  started() {
    this.setProgress(5);
  }

  progress(e) {
    const { progress } = e.detail;
    this.setProgress(5 + (progress * 0.95));
  }

  error(e) {
    super.error(e);

    const {error} = e.detail;

    e.preventDefault();
    this.element.setAttribute('title', error);
    this.input.classList.remove('_hidden');
  }

   end() {
    this.element.classList.add('-completed')
    this.disableForm(false);
   }
}
