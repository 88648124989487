import Chart from 'chart.js/auto';
import chartSettings, { sessionChartColours } from '../../../config/chart-config'

export default class SessionsChart {
  ctx;
  data;
  dataSets = [];
  colours = sessionChartColours;

  constructor(ctx, data){
    this.ctx = ctx;
    this.data = data;
    chartSettings();

    if(this.data && this.data.times && this.data.times.length && (this.data.audio && this.data.audio.length  || this.data.video && this.data.video.length)) {
      this._setDataSets();
      this._createChart();
      this._makeVisible();
      return this.chart;
    }
  }

  _setDataSets(){
    const lines = ['audio', 'video'];

    lines.forEach((line) => {
      if(this.data[line]){
        this.dataSets.push({
          label:  line[0].toUpperCase() + line.slice(1),
          data: this.data[line],
          backgroundColor: this.colours[line].backgroundColor,
          pointBackgroundColor: this.colours[line].pointBackgroundColor,
          borderColor: this.colours[line].borderColor,
          pointRadius: 4,
          pointHoverRadius: 5.5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#333',
        })
      }
    })
  }

  _createChart(){
    this.chart = new Chart( this.ctx, {
      type: 'line',
      data: {
        labels: this.data.times,
        datasets: this.dataSets
      },
      options: {
        animations: false,
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          mode: 'index', // Show data of all lines at that point
          position: 'nearest' // Show tooltip at nearest point
        },
        elements: {
          line: { tension: 0.2 } // straightness of line
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxHeight: 5,
              boxWidth: 5,
              usePointStyle: true
            }
          },
        },
        scales: {
          y: {
            border: {
              display: false,
              dash: [1]
            }
          },
          x: {
            ticks: {
              maxRotation: 0, // Do not rotate ticks on small screens
              padding: 2, // Distance of labels and graph
            },
            grid: {
              drawOnChartArea: false // only want the grid lines for one axis to show up
            }
          }
        },
      }
    });
  }

  _makeVisible() {
    this.ctx.canvas.parentElement.parentElement.classList.remove('_hidden');
  }
}
