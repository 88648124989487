import $ from "jquery";

const prevBtnClass = '.btn.monthprev';
const nextBtnClass = '.btn.monthnext';
const monthSelectClass = 'select[name="month-switch"]'

export default function monthSwitcher() {
  if ($(monthSelectClass).length) {
    toggleSwitchButtons();
    setEventListeners();
  }
}

function setEventListeners() {
  $(document).on('click', prevBtnClass, () => selectPrevMonth());
  $(document).on('click', nextBtnClass, () => selectNextMonth());
  $(document).on('change', monthSelectClass, (e) => changeLocation(e.target.value));
}

function selectPrevMonth() {
  const prevItem = $(`${monthSelectClass} option:selected`).prev();

  if (prevItem) {
    changeLocation(prevItem.val());
    prevItem.prop('selected', true);
  }
}

function selectNextMonth() {
  const nextItem = $(`${monthSelectClass} option:selected`).next();

  if (nextItem) {
    changeLocation(nextItem.val());
    nextItem.prop('selected', true);
  }
}

function toggleSwitchButtons() {
  const selectedIndex = $(monthSelectClass).prop("selectedIndex");
  const showPrevBtn = selectedIndex === 0;
  const showNextBtn = $(`${monthSelectClass} option`).length === (selectedIndex + 1);

  $(prevBtnClass).prop('disabled', showPrevBtn);
  $(nextBtnClass).prop('disabled', showNextBtn);
}

function changeLocation(location) {
  $.getScript(location, () => {
    toggleSwitchButtons();
    updateTitle();
  });
}

function updateTitle() {
  const title = $('.page-title')[0];
  const monthText = $(`${monthSelectClass} option:selected`)[0].textContent;

  title.replaceChild(document.createTextNode(monthText), title.firstChild);
}
