export default class ExportBtns {
  element = document.querySelector('.export-btns');
  succesNoticeEl = document.querySelector('.copy-notice.-success');
  errorNoticeEl = document.querySelector('.copy-notice.-error');

  hide() {
    this.element.classList.add('_hidden');
  }

  show() {
    this.element.classList.remove('_hidden');
  }

  onSuccess() {
    this._showAndHideNotice(this.succesNoticeEl)
  }

  onError(e) {
   console.error(e);

   this._showAndHideNotice(this.errorNoticeEl)
  }

  _showAndHideNotice(noticeEl) {
    noticeEl.classList.remove('_hidden');

    setTimeout(() => noticeEl.classList.add('_hidden'), 1500)
  }
}
