import Clipboard from 'clipboard';
import $ from 'jquery';
import ExportBtns from './exporterBtns';
import Exporter from '../utils/exporter';
import StatsApi from '../utils/statsApi';

export default class StatsTable {
  statsRow;
  apiIndex;
  statsApi;
  rows = [];
  pageLimit = 1000;
  exportBtns = new ExportBtns();
  exporter = Exporter;
  element = document.querySelector('#stats-table');

  init(el) {
    this.element = el;
    this.statsApi = new StatsApi(el.dataset);
    this.exporter = new this.exporter(el.dataset);

    this.getDataAndFillRows();
    this._setExpandCollapseRows();
    this._setExportButtons();
  }

  getDataAndFillRows(period) {
    this.exportBtns.hide();

    this.statsApi[this.apiIndex](this._getPageLimit(), period)
      .then((response) => this._processRowData(response.data))
  }

  _getPageLimit() {
    return this.pageLimit;
  }

  _processRowData(data) {
    this._clearRows();
    this.data = data;

    if(this.data && this.data.length) {
      $('.statsalert').addClass('_hidden')

      this._fillRows();
      this._openFirstRow();

      this.element.classList.remove('_hidden');
      this.exportBtns.show();
    } else {
      this._showEmptyNotice();
      this.exportBtns.hide();
    }
  }

  _clearRows() {
    this.rows.forEach(row => row.destroy())
    this.element.innerHTML = '';
    this.rows = [];
  }

  _fillRows() {
    this.data.forEach((tabData) => {
      const row = new this.statsRow(tabData, this.statsApi);
      row.init();
      this.element.appendChild(row.element);
      this.rows.push(row);
    })
  }

  _openFirstRow() {
    this.element.querySelector('.stats-row .statsrow-header').click();
  }

  _setExpandCollapseRows() {
    document.querySelector('.btn.-expand').onclick = () => this._expandAll();
    document.querySelector('.btn.-contract').onclick = () => this._collapseAll();
  }

  _expandAll() {
    const tabs = this.element.querySelectorAll('.stats-row');

    tabs.forEach( (tab) => {
      if(!tab.classList.contains('opened')){
        tab.querySelector('.statsrow-header').click();
      }
    })
  }

  _collapseAll() {
    const tabs = this.element.querySelectorAll('.stats-row.opened')

    tabs.forEach( (tab) => tab.classList.remove('opened'))
  }

  _setExportButtons() {
    this._copyButtonEvents()
    this._downloadButtonEvents()
  }

  _downloadButtonEvents() {
    const parentEl = $('body');
    parentEl.on('click', '.btn.-download', () => this.exporter.downloadFile(this.data, this._exportTitle()));
    parentEl.on('click', '.btn.-download-all', () => this.exporter.downloadFile(this.data, this._exportTitle(), true));
  }

  _exportTitle() {
    return i18n.stats.title;
  }

  _copyButtonEvents(){
    const clipboard = new Clipboard('.btn.-copy', {
      text: () => this.exporter.getCsvContent(this.data)
    });

    clipboard.on('success', () => this.exportBtns.onSuccess());
    clipboard.on('error', (e) => this.exportBtns.onError(e));
  }

  _showEmptyNotice() {
    document.querySelector('.-emptynotice').classList.remove('_hidden');
    this.element.classList.add('_hidden');
  }
}
