import Croppr from 'croppr';

const cropImageEl = document.getElementById('cropimage');

export default function imageCropper() {
  if (cropImageEl) new ImageCropper(cropImageEl)
};


class ImageCropper {
  scale;
  size;
  minSize;
  croppr;
  cropTextfieldEl = document.getElementById('image_crop');
  tooSmallAlertEl = document.querySelector('.alert-box.-too-small');

  constructor(imageEl) {
    if(!imageEl.complete) {
      imageEl.addEventListener('load', () => this.init(imageEl))
    }

    this.init(imageEl);
  }

  init(imageEl) {
    this.setImageScale(imageEl);
    this.setMinSize(imageEl.dataset.minSize)
    this.setImageSize(imageEl);
    this.makeCroppable(imageEl);
  }

  makeCroppable(image) {
    const minSize = this.minSize;

    this.croppr = new Croppr(image, {
      aspectRatio: 9 / 16, // Note: on a scale of 0 to 1, so needs to be reversed
      minSize,
      startSize: [this.size.w, this.size.h, 'px'],
      onInitialize: (instance) => this.moveToCorrectPlace(instance, this.size),
      onCropEnd: (sizeObj) => this.checkMinSize(sizeObj)
    });
  }

  setImageScale(image) {
    this.scale = image.clientWidth / image.naturalWidth;
  }

  setImageSize(image) {
    let currentSize = JSON.parse(image.dataset.currentSize);

    Object.keys(currentSize).forEach((key) => {
      const value = currentSize[key];
      currentSize[key] = value * this.scale;
    })

    this.size = currentSize;
  }

  setMinSize(minSizeString) {
    const minSize = minSizeString.split(',');
    const x = parseFloat(minSize[0]) * this.scale;
    const y = parseFloat(minSize[1]) * this.scale;
    this.minSize =  [x, y, 'px'];
  }

  moveToCorrectPlace(cropInstance, currentSize) {
    cropInstance.moveTo(currentSize.x, currentSize.y);
  }

  checkMinSize(sizeObj) {
    if (this.croppr && (sizeObj.width < this.minSize[0] || sizeObj.height < this.minSize[1])) {
      this.showTooSmallAlert()
      this.croppr.reset();
    }

    this.setTextField(sizeObj)
  }

  showTooSmallAlert() {
    this.tooSmallAlertEl.classList.remove('_hidden');

    setTimeout(() => this.tooSmallAlertEl.classList.add('_hidden'), 2500)
  }

  setTextField(sizeObj) {
    const x2 = sizeObj.x + sizeObj.width;
    const y2 = sizeObj.y + sizeObj.height;
    this.cropTextfieldEl.value = [sizeObj.x, sizeObj.y, x2, y2].toString();
  }
}


