export default function base() {
  baseFormSettings();
}

function baseFormSettings(){
  document.querySelectorAll('textarea').forEach(
      (textarea) => textarea.addEventListener('keydown', submitForm)
  )
}

function submitForm(e) {
  if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
    e.target.closest('form').submit();
  }
}
